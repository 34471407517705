(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
  ga('create', 'UA-61038922-1', 'auto');
  ga('send', 'pageview');

$(window).load(function () {
    setTimeout(function() {
	$('#myModal').modal();
	}, 3000);  
});

setTimeout(function() {
  $('#flashMessage').fadeOut('fast');
}, 5500); //time in milliseconds

$(function() {
  $('input[name="serviceDate"]').daterangepicker({
    locale: {
      format: 'YYYY-MM-DD',
    },
    singleDatePicker: true,
    showDropdowns: true,
  });
});

$(function() {
  $('input[name="regDate"]').daterangepicker({
    locale: {
      format: 'YYYY-MM-DD',
    },
    singleDatePicker: true,
    showDropdowns: true,
  });
});

$(function() {
  $('input[name="dob"]').daterangepicker({
    locale: {
      format: 'YYYY-MM-DD',
    },
    singleDatePicker: true,
    showDropdowns: true,
  });
});

jQuery().ready(function() {
  var v = jQuery("#basicform").validate({
    rules: {
      name: {
        required: true,
        minlength: 2,
        maxlength: 16,

      },
      mobile:{
        required: true,
        minlength: 10,
        maxlength: 10,

      },
      email: {
        required: true,
        email: true,

      },
      dob: {
        required: true,
      },
      address: {
        required: true,
      },
      housingStatus: {
        required: true,
      },
      pan: {
        required: true,
        minlength:5,
        maxlength:15,
      },
      empStatus: {
        required: false,
      },
      empSource:{
        required:false,
      },
      workPosition:{
        required:false,
      },
      workMobile:{
        required:true,
        maxlength:15,
      },
      annualIncome:{
        required:true,
      },
      clientDesc:{
        required:true,
      },
      carCompany:{
        required:true,
        minlength:2,
        maxlength:15,
      },
      carModel:{
        required:true,
        minlength:2,
        maxlength:15,
      },
      modelLaunchYear:{
        required:true,
        minlength:4,
        maxlength:4,
      },
      
      price:{
        required:true,
      },
      transmission:{
        required:true,
      },
      bodyType:{
        required:true,
      },
      fuel:{
        required:true,
      },
      carDesc:{
        required:true,
      }
    },
    errorElement: "span",
    errorClass: "help-inline-error",
  });

  $(".open1").click(function() {
    if (v.form()) {
      $(".frm").hide("fast");
      $("#sf2").show("slow");
    }
  });

  $(".open2").click(function() {
    if (v.form()) {
      $(".frm").hide("fast");
      $("#sf3").show("slow");
    }
  });

  $(".back2").click(function() {
    $(".frm").hide("fast");
    $("#sf1").show("slow");
  });

  $(".back3").click(function() {
    $(".frm").hide("fast");
    $("#sf2").show("slow");
  });

  $(".open3").click(function() {
    if (v.form()){}
  });
});

(function() {
  var s = document.createElement("script");
  s.type = "text/javascript";
  s.async = true;
  s.src="http://cdn.calculatestuff.com/resizer.js";
  (document.getElementsByTagName("head")[0] || document.getElementsByTagName("body")[0]).appendChild(s);
})();

     

var audio = new Audio('audio/jingle.mp3');


