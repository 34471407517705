var app =angular.module('myapp', ['ngResource','ui.bootstrap','rzModule'], function ($interpolateProvider) {
    $interpolateProvider.startSymbol('<%');
    $interpolateProvider.endSymbol('%>');
});


var url="";
app.factory('services', ['$resource', function ($resource) {
    return {

        listingsLaravel: $resource(url+'listingsLaravel/:id',{id: "@id"},{
            'update': { method:'PATCH' }
        }),
        front_company: $resource(url+'front_company/:id',{id: "@id"},{
            'update': { method:'PATCH' }
        }),
        front_model: $resource(url+'front_model/:id',{id: "@id"},{
            'update': { method:'PATCH' }
        }),
        front_bodyType: $resource(url+'front_bodyType/:id',{id: "@id"},{
            'update': { method:'PATCH' }
        }),
        front_fuelType: $resource(url+'front_fuelType/:id',{id: "@id"},{
            'update': { method:'PATCH' }
        }),
        filter_cars: $resource(url+'filter_cars/:id',{id: "@id"},{
            'update': { method:'PATCH' }
        }),
        front_carInfo: $resource(url+'front_carInfo/:id',{id: "@id"},{
            'update': { method:'PATCH' }
        }),
        front_buyer : $resource(url+'front_buyer/:id',{id: "@id"},{
           'update': { method:'PATCH' }
       }),
        finance: $resource(url+'finance-Loan/:id',{id:"@id"},{
           'update':{method:'PATCH'}
       }),
        front_carServiceAppoint: $resource(url + 'car-Service-Appoint/:id', {id: "@id"}, {
           'update': {method: 'PATCH'}
       }),
        front_blockServiceDate: $resource(url + 'block-Service-Date/:id', {id: "@id"}, {
           'update': {method: 'PATCH'}
       }),
    };
}]);

app.controller('compareCarDetailsController',['$http','$log','$scope','$timeout','services','$rootScope','$filter','$location','$window',function($http,$log,$scope,$timeout,services,$rootScope,$filter,$location,$window)
{
    $scope.carInfoList=[];
    $scope.isneedmsg = false;
    $scope.cmpbtn=false;
    $scope.sorrynocars=false;
    $scope.hideshowinventory = function(){
        if($scope.carInfoList.length != 0){
            return "showinventory";
        }
        else{
            return "hideinventory";
        }
    }
    $scope.init = function(){
        $scope.isprocessing = true;
        services.listingsLaravel.query({},function(response){
            //$scope.carInfoList = response;
            $scope.carInfoList = response[0].data;
            angular.forEach($scope.carInfoList,function(add)
            {
                add['checked']=false;
                if(add['transmission']=='1')
                {
                    add['transmission']="Automatic";
                }
                else
                {
                    add['transmission']="Manual";
                }
                add['regDate']=$filter('date')(new Date(add['regDate']),'yyyy-MM');
            });
            $scope.totalItems = response[0].total;
            $scope.prev_page = response[0].prev_page_url;
            $scope.currentPage = response[0].current_page;
            $scope.next_page = response[0].next_page_url;
            $scope.from=response[0].from;
            $scope.to=response[0].to;
            if($scope.carInfoList.length == 0)
                $scope.isneedmsg = true;
            else
                $scope.isneedmsg = false;
        });
        $scope.companyList=[];
        services.front_company.query({},function(response){
            $scope.companyList=response;
            $scope.company="";
        })
        $scope.modelList=[];
        $scope.getModel=function(){
            services.front_model.query({companyId:$scope.company},function(response){
                $scope.modelList=response;
                $scope.model="";
            })
        }
        $scope.bodyTypeList=[];
        services.front_bodyType.query({},function(response){
            $scope.bodyTypeList=response;
        })
        $scope.fuelTypeList=[];
        services.front_fuelType.query({},function(response){
            $scope.fuelTypeList=response;
        });
        window.setTimeout(function() {
            $(".alert").fadeTo(500, 0).slideUp(500, function(){
                $(this).remove();
            });
        }, 2000);
        $scope.isprocessing = false;
    };
    $scope.init();
    
    //Range slider config
    $scope.minRangeSlider = {
        minValue: 10,
        maxValue: 80,
        options: {
            floor: 0,
            ceil: 1000,
            step: 1
        }
    };

    $scope.setPage = function (pageNo) {
        $scope.currentPage = pageNo;
    };
    $scope.pageChanged = function(currentPage) {
        window.scrollTo(0,0);
        $scope.carInfoList = [];
        if($scope.company!=""||$scope.model!=""||$scope.bodyType!=""||$scope.fuelType!="")
        {
            services.filter_cars.query({page:currentPage,companyId:$scope.company,modelId:$scope.model,bodyType:$scope.bodyType,fuelType:$scope.fuelType}, function (response) {
                if(response[0].data.length!=0){
                    $scope.carInfoList = response[0].data;
                    if($scope.carInfoList.length == 0)
                        $scope.isneedmsg = true;
                    else
                        $scope.isneedmsg = false;
                    angular.forEach($scope.carInfoList,function(add)
                    {
                        add['checked']=false;
                        if(add['transmission']=='1')
                        {
                            add['transmission']="Automatic";
                        }
                        else
                        {
                            add['transmission']="Manual";
                        }
                        add['regDate']=$filter('date')(new Date(add['regDate']),'yyyy-MM');
                    });
                    $scope.totalItems = response[0].total;
                    $scope.prev_page = response[0].prev_page_url;
                    $scope.currentPage = response[0].current_page;
                    $scope.next_page = response[0].next_page_url;
                    $scope.from=response[0].from;
                    $scope.to=response[0].to;
                    angular.forEach($rootScope.compareCarId,function(compare)
                    {
                        angular.forEach($scope.carInfoList,function(all)
                        {
                            if(all['id']==compare)
                            {
                                all['checked'] = true;
                            }

                        });
                    });
                }
                else {
                }
            });
        }
        else{
            services.listingsLaravel.query({page:currentPage},function(response){
                $scope.carInfoList = response[0].data;
                if($scope.carInfoList.length == 0)
                    $scope.isneedmsg = true;
                else
                    $scope.isneedmsg = false;
                angular.forEach($scope.carInfoList,function(add)
                {
                    add['checked']=false;
                    if(add['transmission']=='1')
                    {
                        add['transmission']="Automatic";
                    }
                    else
                    {
                        add['transmission']="Manual";
                    }
                });
                $scope.totalItems = response[0].total;
                $scope.prev_page = response[0].prev_page_url;
                $scope.currentPage = response[0].current_page;
                $scope.next_page = response[0].next_page_url;
                $scope.from=response[0].from;
                $scope.to=response[0].to;

                angular.forEach($rootScope.compareCarId,function(compare)
                {
                    angular.forEach($scope.carInfoList,function(all)
                    {
                        if(all['id']==compare)
                        {
                            all['checked'] = true;
                        }

                    });
                });        
            });
        }
    };

    $rootScope.compareCarId = [];

    $scope.compare = function(id) {
        $scope.found=0;
        $scope.checkId = id;
        angular.forEach($rootScope.compareCarId, function(carId) {
            if (carId == $scope.checkId) {
                $scope.found = 1;
            }
        })
        if ($scope.found == 1)
            $rootScope.compareCarId.splice($rootScope.compareCarId.indexOf($scope.checkId), 1);
        else {
            $rootScope.compareCarId.push($scope.checkId);
        }
        if($rootScope.compareCarId.length==2 || $rootScope.compareCarId.length==3)
        {
            $scope.cmpbtn=true;
        }
        else{
            $scope.cmpbtn=false;
        }
    }

    $scope.filterCars=function()
    {
        $rootScope.compareCarId = [];
        $scope.carInfoList=[];
        services.filter_cars.query({companyId:$scope.company,modelId:$scope.model,bodyType:$scope.bodyType,fuelType:$scope.fuelType}, function (response) {
            if(response[0].data.length!=0){
                $scope.carInfoList = response[0].data;
                if($scope.carInfoList.length == 0)
                    $scope.isneedmsg = true;
                else
                    $scope.isneedmsg = false;
                angular.forEach($scope.carInfoList,function(add)
                {
                    add['checked']=false;
                    if(add['transmission']=='1')
                    {
                        add['transmission']="Automatic";
                    }
                    else
                    {
                        add['transmission']="Manual";
                    }
                });
                $scope.totalItems = response[0].total;
                $scope.prev_page = response[0].prev_page_url;
                $scope.currentPage = response[0].current_page;
                $scope.next_page = response[0].next_page_url;
                $scope.from=response[0].from;
                $scope.to=response[0].to;
                angular.forEach($rootScope.compareCarId,function(compare)
                {
                    angular.forEach($scope.carInfoList,function(all)
                    {
                        if(all['id']==compare)
                        {
                            all['checked'] = true;
                        }

                    });
                });
            }
            else {
                $scope.sorrynocars=true;
            }
        })
    }
    $scope.clear=function(){
        $scope.company="";
        $scope.model="";
        $scope.bodyType="";
        $scope.fuelType="";
    }
    $scope.compareShow=function(){
        $scope.compareLength=$rootScope.compareCarId.length;
        if($scope.compareLength==2){
            $window.location.href ='compare-car-details?car1=' +$rootScope.compareCarId[0] +'&car2='+$rootScope.compareCarId[1];
        }
        else{
            $window.location.href ='compare-car-details?car1='+$rootScope.compareCarId[0] +'&car2='+$rootScope.compareCarId[1] +'&car3='+$rootScope.compareCarId[2];
        }
    }

    $scope.getRef= function(carInfo){
        if(carInfo.is_sold == "1"){
            return "#";
        }
        else{
            carInfo['companyName']=carInfo['companyName'].replace(/ /g , "-");
            carInfo['modelName']=carInfo['modelName'].replace(/ /g , "-");
            var carname=carInfo['companyName']+'-'+carInfo['modelName'];
            return "certified-second-hand-used-cars-pune/"+"b"+carInfo.id+"-"+carname;
        }
    }
}]);


app.controller('rtoStatusController',['$log','$scope','$resource','services','$window','$location','$filter',function($log,$scope,$resource,services,$window,$location,$filter)
{
	$scope.found=false;
	$scope.rtoStatus=function()
	{
		services.front_carInfo.query({regNo:$scope.regNo,rtoStatus:1},function(response)
        {
            if(response.length!=0)
            {
                if(response[0].rtoStatus == ""){
                    $scope.found=true;
                    $scope.rtoStatusDesc="No Updates";
                    $scope.regNo="";
                }
                else{
                    $scope.found=true;
                    $scope.rtoStatusDesc=response[0].rtoStatus;
                    $scope.regNo="";
                }
            }
            else{
                $scope.found=true;
                $scope.rtoStatusDesc="Registration No. Not Available";
                $scope.regNo="";
            }
        });
	}
}]);


app.controller('financeController',['$log','$scope','$resource','services','$window','$location','$filter',function($log,$scope,$resource,services,$window,$location,$filter)
{
	$scope.success=false;
	$scope.addFinance=function(){
		if($("#basicform").valid()){
			data={
				name:$scope.name,
				mobile:$scope.mobile,
				email:$scope.email,
				dob:$filter('date')(new Date($scope.dob),'yyyy-MM-dd'),
				address:$scope.address,
				housingStatus:$scope.housingStatus,
				pan:$scope.pan,
				empStatus:$scope.empStatus,
				empSource:$scope.empSource,
				workPosition:$scope.workPosition,
				workMobile:$scope.workMobile,
				annualIncome:$scope.annualIncome,
				clientDesc:$scope.clientDesc,
				carCompany:$scope.carCompany,
				carModel:$scope.carModel,
				modelLaunchYear:$scope.modelLaunchYear,
				price:$scope.price,
				transmission:$scope.transmission,
				bodyType:$scope.bodyType,
				fuel:$scope.fuel,
				carDesc:$scope.carDesc
			}
			services.finance.save({},data,function(response){
				$scope.success=true;
				window.setTimeout(function() {
					$(".alert").fadeTo(500, 0).slideUp(500, function(){
						$(this).remove();
					});
				}, 1000);
				$scope.name="";
				$scope.mobile="";
				$scope.email="";
				$scope.dob="";
				$scope.address="";
				$scope.housingStatus="";
				$scope.pan="";
				$scope.empStatus="";
				$scope.empSource="";
				$scope.workPosition="";
				$scope.workMobile="";
				$scope.annualIncome="";
				$scope.clientDesc="";
				$scope.carCompany="";
				$scope.carModel="";
				$scope.modelLaunchYear="";
				$scope.price="";
				$scope.transmission="";
				$scope.bodyType="";
				$scope.fuel="";
				$scope.carDesc="";
                $window.location.href="finance-Loan";
            })
		}
	}
}]);

app.controller('carServiceAppointController',['$log','$scope','$http','$resource','services','$window','$location','$filter','$rootScope','$timeout','$q',function($log,$scope,$http,$resource,services,$window,$location,$filter,$rootScope,$timeout,$q)
{
   $scope.success=false;
   $scope.btnsend=false;
   $scope.blockedDate=[];
   $scope.flagBlockDate=0;
   $scope.flagThursday=0;
  
    $scope.init=function(){
        services.front_blockServiceDate.query({},function(response){
            $scope.blockedDate=response;
              $scope.dt = "";
          });
    }

    $scope.init();
    var someDate = new Date();
    someDate.setDate(someDate.getDate() + 20); 
    $scope.options ={
        customClass: getDayClass,
        minDate: new Date(),
        showWeeks: false,
        maxDate :someDate,
        dateDisabled:disabled
    };

    $scope.today = function() {
        $scope.dt = new Date();
    };
    //$scope.today();

    $scope.clear = function() {
        $scope.dt = null;
    };

    $scope.clear();


    //Disable weekend selection
    function disabled(data){
        var date = data.date,
        mode = data.mode;

        // var holidays = [
        // new Date(2016,8,25),
        // new Date(2016,8,26),
        // new Date(2016,8,27),

        // ]


        var isHoliday = false;
        angular.forEach($scope.blockedDate,function(obj)
        {
            if(areDatesEqual(obj.blockServiceDate, date)){
                isHoliday = true;
            }
        });
        // for(var i = 0; i < holidays.length ; i++) {
        //     if(areDatesEqual(holidays[i], date)){
        //         isHoliday = true;
        //     }
        //     //return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);  
        // }
        return mode === 'day' && (date.getDay() === 2 || isHoliday);
    };

    function areDatesEqual(date1, date2) {

        var dt2 = $filter('date')(new Date(date2),'yyyy-MM-dd');
        return date1 === dt2
    }

    $scope.setDate = function(year, month, day) {
        $scope.dt = new Date(year, month, day);
    };

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date(tomorrow);
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    $scope.events=[];
    // $scope.events = [
    // {
    //   date: tomorrow,
    //   status: 'full'
    // },
    // {
    //   date: afterTomorrow,
    //   status: 'partially'
    // }
    // ];


    function getDayClass(data){
        var date = data.date,
        mode = data.mode;
        if (mode === 'day') {
            var dayToCheck = new Date(date).setHours(0,0,0,0);
            for (var i = 0; i < $scope.events.length; i++) {
                var currentDay = new Date($scope.events[i].date).setHours(0,0,0,0);
                if (dayToCheck === currentDay){
                    return $scope.events[i].status;
                }
            }
        }
        return '';
    }

    $scope.carServiceAppoint=function(){
        $scope.btnsend=true;
        data={
            crn: $scope.crn,
            name: $scope.name,
            phone: $scope.phone,
            email: $scope.email,
            serviceDate:$filter('date')(new Date($scope.dt),'yyyy-MM-dd'),
            focusArea: $scope.focusArea,
            pick:$scope.pick,
            pickAdd:$scope.pickAdd,
            drop:$scope.drop,
            dropAdd:$scope.dropAdd
        }
        if(data.serviceDate != "Invalid Date"){
            services.front_carServiceAppoint.save({},data,function(response){
                $scope.crn="";
                $scope.name="";
                $scope.phone="";
                $scope.email="";
                $scope.focusArea="";
                $scope.serviceDate="";
                $scope.dt="";
                $scope.pick="";
                $scope.pickAdd="";
                $scope.drop="";
                $scope.dropAdd="";
                $scope.success=true;
                $scope.btnsend=false;
               $http.get('http://sms.hspsms.com/sendSMS?username=budget&message=Thank You for contacting Budget Pre-owned Cars. Request you to get back to you in eight hours. Regards, Budget Pre-owned Cars&sendername=budget&smstype=TRANS&numbers='+response['phone']+'&apikey=1af441bd-c2df-4a65-9e55-ba62c176b0d4')
               .then(function(response){});
               $http.get('http://sms.hspsms.com/sendSMS?username=budget&message=Request For Service Approval ,'+response['crn']+','+response['name']+','+response['phone']+','+response['serviceDate']+' &sendername=budget&smstype=TRANS&numbers=7276300088&apikey=1af441bd-c2df-4a65-9e55-ba62c176b0d4')
               .then(function(response){});
            });
        }
        else{
            window.alert("Select Service Date");
            $scope.btnsend=false;
        }
    }
}]);
